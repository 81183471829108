.wrapper {
  border-top: 1px solid #a0a0a0;
  padding: 16px 32px 16px 32px;

  .image {
    width: 100%;
    min-width: 175px;
    max-width: 450px;
    height: 40px;
    background-size: auto 40px;
    background-position: center;
  }
}

.actionBox {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 20px 0;
}

.wideButton {
  margin-left: 10px;
  width: max-content;
  display: flex;
  gap: 10px;
}


.pay_buy_crypto {
  width:400px;
  height: 40px;
}

.pay_buy_crypto_text {
  align-items: center;
}