.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 400px;
  max-height: 600px;
  padding: 20px 10px;
  margin: 10px 0;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 15px -3px rgb(33 243 133 / 29%), 0 4px 6px -4px rgb(151 243 33 / 15%);
  border-radius: 10px;
  background: #ffffff;

  @media screen and (max-width: 500px) {
    max-width: 350px;
	}
}
