.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 11px;

  height: 40px;
  max-height: 50px;

  /* white */
  background: #6fb242;
  box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 40%);
  /* stroke blue */
  box-sizing: border-box;
  border-radius: 8px;

  cursor: pointer;

  font-size: 14px;
  font-weight: bold;

  margin: 0;
  transition: 0.2s;
}

.button:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.secondary {
}

.text {
  background: transparent;
  border: 0;
}

.primary {
  color: white;
}
