.mainContainer {
  width: 100vw;
  max-width: 1200px;
  box-shadow: 0px 0px 5px #ccc;
}

.logoPlace {
  padding: 20px;

  img {
    height: 32px;
  }
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  background-color: #7e7d86;
  padding: 16px 32px 16px 32px;
  color: white;
  font-size: 32px;
  font-weight: bold;
}

.order {
  font-weight: 500;
  font-size: 1.3em;
}

.orderDetails {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 16px 32px;
  background-color: #EFEEF3;
  color: black;
}

.orderTitle {
  font-weight: 300;
  font-size: 0.8em;
}

.amount {
  color: #c4485b;
  font-weight: 600;
}

.modalContainer {
  margin: auto;
  /* background-color: white; */
  padding: 20px;
}

.customerData {
  color: rgba(70, 56, 139, 0.7);
  font-style: italic;
}
