.flexBox {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    width: 100%;
  }
  
  button {
    background-color: #f9eca9;
    box-shadow: rgb(39 196 196) 0px 0px 8px 0px;
    border: none;
    border-radius: 30px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
}

.wrapper {
  border: 1px solid black;
  max-width: 800px;
  margin: 20px;
  padding: 70px 10px 15px 10px;
}

.customerInput {
  height: 60px;
  min-height: 60px;
  width: 70%;
  margin-bottom: 25px;
  font-size: 16px;
}

@media (max-width: 800px) {
  .customerInput {
    width: 95%;
  }
}
