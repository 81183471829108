$first: rgba(223, 223, 223, 0.408);

.loader {
  background: transparent;
  padding: 35px;
  box-sizing: border-box;
  border-color: blue;
  border-radius: 50%;
  border: solid 25px transparent;
  animation: anim 2.5s linear infinite;
  border-top: solid 25px $first;
  border-bottom: solid 25px $first;
}

@keyframes anim {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}