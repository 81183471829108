.content {
  padding-bottom: 20px;
}

.content .price {
  color: rgb(20, 20, 20);
  font-weight: 800;
  font-size: 50px;
}

.content .description {
  color: rgb(36, 36, 36);
  margin-top: 10px;
  font-size: 14px;
}

.content .title {
  font-weight: 800;
  text-transform: uppercase;
  color: rgb(20, 20, 20);
  font-size: 25px;
  letter-spacing: 1px;
}

.button_card {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 700;
  font-size: .75rem;
  padding: 0.75rem 1.5rem;
  background-color: #6fb242;
  border-radius: 0.5rem;
  width: 90%;
}